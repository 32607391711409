<template>
  <div class="swiper-wrapper">
    <swiper class="swiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in textList" :key="'com_swiper_'+index">
        <p>
          {{ item.split(" ")[0] }} <br />
          <span>{{ item.split(" ")[1] }}</span>
        </p>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "carrousel",
  props: {
    textList: Array,
  },
  data() {
    return {
      swiperOptions: {
        direction: "horizontal",
        loop: true,
        loopAdditionalSlides: 3,
        slidesPerView: 1,
        hideOnClick: true,
        autoplay: {
          delay: 5000,
        },
        themeColor: "#fff",
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper-wrapper ::v-deep .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
.swiper {
  height: 200px;
  p {
    margin-left: auto;
    margin-right: auto;
    text-indent: 2em;
    width: 1005px;
    line-height: 36px;
    font-size: 18px;
    font-family: PingFang SC Regular;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
    span {
      display: block;
      text-align: right;
    }
  }
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 1023px) {
  .swiper {
    height: 200px;
    p {
      width: 86vw;
      font-size: 13px;
      line-height: 25px;
    }
  }
}
</style>