<template>
  <div class="home">
    <div class="giac-container">
      <div class="banner">
        <img class="bg" src="../assets/img/banner4.png" width="100%" />
        <p class="place-time">{{ this.place }}<br />{{ this.time }}</p>
      </div>
      <div class="banner-ul">
        <ul>
          <li>
            <h2>24</h2>
            <p>分会场主题<br />覆盖主流架构话题</p>
          </li>
          <li>
            <h2>84</h2>
            <p>热门案例<br />深度解密架构演进</p>
          </li>
          <li>
            <h2>1000+</h2>
            <p>架构师<br />共同学习共同进步</p>
          </li>
        </ul>
      </div>
      <div class="giac">
        <div class="space meeting-intro"></div>
        <div class="public-title">
          <div class="inner">
            <em class="lt"></em>
            <em class="rt"></em>
            <em class="lb"></em>
            <em class="rb"></em>
            <p>GIAC 2022</p>
            <h4>会议介绍</h4>
          </div>
        </div>
        <div class="meeting-intro">
          <p class="text">
            全球互联网架构大会(简称“GIAC”)是长期关注互联网技术与架构的高可用架构技术社区和msup推出的面向架构师、技术负责人及高端技术从业人员的年度技术架构大会，是中国地区规模最大的技术会议之一。
            第八届GIAC，组委会从互联网架构热门的前沿趋势、云原生、智能数据、爆款架构演进、卓越工程实践等领域甄选前沿的有典型代表的技术创新及研发实践的架构案例，分享他们在本年度值得的总结、盘点的实践启示，打造一个分享及讨论平台，帮助和指导听众了解和改进互联网构建方式。
          </p>
        </div>
      </div>
      <div class="buy-ticket pc">
        <img class="buy-bg" src="../assets/img/buy-bg.png" alt="" />
        <div class="buy-content">
          <p>GLOBAL INTERNET ARCHITECTURE CONFERENCE</p>
          <img class="line" src="../assets/img/line.png" alt="" />
          <div>
            <button><a @click="toOrder">立即购票</a></button>
            <!-- <button><router-link to="">已售罄</router-link></button> -->
          </div>
        </div>
      </div>
      <div class="buy-ticket mob">
        <img class="buy-bg" src="../assets/img/mob-buy-bg.png" alt="" />
        <div class="buy-content">
          <div>
            <button><a @click="toOrder">立即购票</a></button>
            <!-- <button><router-link to="">已售罄</router-link></button> -->
          </div>
        </div>
      </div>
      <div class="giac">
        <div class="space chairman-publisher"></div>
        <div class="public-title">
          <div class="inner">
            <em class="lt"></em>
            <em class="rt"></em>
            <em class="lb"></em>
            <em class="rb"></em>
            <p>GIAC 2022</p>
            <h4>联席主席与出品人</h4>
          </div>
        </div>
        <Chairman></Chairman>
      </div>
      <div class="theme-setting">
        <h4>GIAC议题设置</h4>
        <p class="pc-theme">
          本届GIAC议题共设置有24个主题，覆盖各类架构热点领域，每个主题由业内知名架构师、<br />技术负责人等专家担任出品人，负责议题选取和质量把控。
        </p>
        <p class="mob-theme">
          本届GIAC议题共设置有24个主题，覆盖各类架构热点领域，每个主题由业内知名架构师、技术负责人等专家担任出品人，负责议题选取和质量把控。
        </p>
        <table class="pc-table">
          <thead>
            <tr>
              <th>议题<br />方向</th>
              <th v-for="(item, index) in themeList" :key="index">
                {{ item[0] && item[0].venueName }} /
                {{ item[1] && item[1].venueName }}/
                {{ item[2] && item[2].venueName }} /
                {{ item[3] && item[3].venueName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>拟<br />定<br />专<br />题<br />主<br />题</th>
              <th v-for="(item, index) in themeList" :key="index">
                <div class="content">
                  <div
                    v-for="(i, index) in item"
                    :key="index"
                    @click="toSubject(i.id)"
                  >
                    {{ i && i.venueName }}
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
        <table class="mob-table">
          <thead>
            <tr>
              <!-- <th>议题方向</th> -->
              <th>拟定专场主题</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in themeList" :key="index">
              <!-- <th>{{ item[0].venueName }}<br />{{ item[1].venueName }}<br />{{ item[2].venueName }}<br />{{ item[3].venueName }}</th> -->
              <th class="content">
                <div class="left">
                  <span @click="toSubject(item[0].id)">{{
                    item[0] && item[0].venueName
                  }}</span>
                  <br /><span @click="toSubject(item[2].id)">{{
                    item[2] && item[2].venueName
                  }}</span>
                </div>
                <div class="right">
                  <span @click="toSubject(item[1].id)">{{
                    item[1] && item[1].venueName
                  }}</span>
                  <br /><span @click="toSubject(item[3].id)">{{
                    item[3] && item[3].venueName
                  }}</span>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="giac">
        <div class="space selected-case"></div>
        <div class="public-title">
          <div class="inner">
            <em class="lt"></em>
            <em class="rt"></em>
            <em class="lb"></em>
            <em class="rb"></em>
            <p>GIAC 2022</p>
            <h4>本期精选案例</h4>
          </div>
        </div>
        <SelectedCase :caseList="selectedCaseList"></SelectedCase>
        <div class="case-wrapper" v-if="selectedCaseList.length > 0">
          <button class="all-case">
            <router-link to="/case">查看全部案例</router-link>
          </button>
        </div>
      </div>
      <div class="comment">
        <img class="pc" src="../assets/img/home-comment-bg.png" width="100%" />
        <img class="mob" src="../assets/img/mob-comment-bg.png" width="100%" />
        <div class="comment-content">
          <h4>参会学员收获</h4>
          <Swiper :textList="commentList"></Swiper>
        </div>
      </div>
      <div class="giac">
        <div class="space sponsor"></div>
        <div class="public-title">
          <div class="inner">
            <em class="lt"></em>
            <em class="rt"></em>
            <em class="lb"></em>
            <em class="rb"></em>
            <p>GIAC 2022</p>
            <h4>参会企业</h4>
          </div>
        </div>

        <SponsorList></SponsorList>
      </div>
      <div class="invitation-wrapper">
        <div class="invitation">
          <img src="../assets/img/invitation-box.png" alt="" />
          <p>第八届2022年GIAC全球互联网架构大会邀请函</p>
          <div>
            <button>
              <a target="_blank" href="http://p.msup.cn/jTH0">案例征集</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "@/components/Swiper.vue";
import SelectedCase from "@/components/SelectedCase.vue";
import Chairman from "@/components/Chairman.vue";
import SponsorList from "@/components/SponsorList.vue";
import {
  sid,
  getMeetingTime,
  getSueLecturer,
  sidlecturer1,
  sidcase,
  getAllCourse,
  getSue,
  sidsponsor,
  getThemeInfo,
  getUserInfo,
} from "@/utils/api.js";

export default {
  name: "Home",
  components: {
    Swiper,
    SelectedCase,
    Chairman,
    SponsorList,
  },
  data() {
    return {
      place: "",
      time: "",
      list: [],
      idList: [],
      selectedCaseList: [],
      caseList: [],
      sponsorList: [],
      // commentList: [
      //   "技术管理专题中cto定位职责方式方法来自老师们的个人建议，为接下来工作提供较多灵感；工程管理专题中devops工具和实践为接下来改进研发组织效能带来可操作性。 —来自苏州数智源信息技术有限公司",
      //   "技术面很丰富，主题覆盖范围广，特别是持续集成的分享，很不错！分布式关系数据库也很不错。 —沪江网技术经理",
      //   "主要关注人工智能时代的应用与发展。收获良多。获得了很多人工智能时代的新视野，通过自学没有办法获取到的知识。 —咪咕互动娱乐有限公司开发工程师",
      // ],
      commentList: [
        "每年参加GIAC是为了看看业界领先的公司都在做些什么，有一些前沿的技术是怎么落地在别人的业务上，这对我们调研新技术、应用新技术都有很多帮助。 -来自华为技术有限公司",
        "参加GIAC，可以现场聆听技术圈的一些大牛的分享，了解大牛们是怎么思考和解决问题的，对拓宽视野，不在研发上走弯路很有用。 -来自平安科技（深圳）有限公司",
        "每年参加GIAC一般都是组团来，每个技术线都会来一些同学，主要是学习一些落地实践，大家都学习进步都快一些，每次会后也组织内部分享，是很好地学习方式。 -来自顺丰科技有限公司",
      ],
      themeList: [],
    };
  },
  mounted() {
    this.getPlaceTime();
    this.getChairman();
    // this.getCase();
    this.getSelectedCase();
    this.getSponsor();
    this.getTheme();
  },

  methods: {
    toOrder() {
      const token = localStorage.getItem("token");
      // console.log(token);
      if (token && token !== null) {
        let url = `${getUserInfo}?token=${token}`;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            // console.log("header- checkUserInfo", res);
            if (res.status === "200") {
              let flag = true;
              for (const key in res.data) {
                if (res.data[key] === "") {
                  flag = false;
                  if (window.confirm("购票需要您先完善个人信息")) {
                    this.$router.push({
                      path: "/2022sz/userCenter",
                      query: { active: "5" },
                    });
                  }
                  break;
                }
              }
              if (flag) {
                this.$router.push({
                  path: "/2022sz/order",
                });
              }
            } else if (res.code === "1001" || res.status === "1011") {
              this.$message({
                message: "当前登录状态已过期，请重新登录",
                type: "warning",
              });
              window.localStorage.removeItem("logins");
              window.localStorage.removeItem("token");
              window.localStorage.setItem("linkurl", `/order`);
              this.$router.push({
                path: "/2022sz/login",
              });
            } else {
              console.log("获取用户信息失败");
            }
          });
      } else {
        this.$message({
          message: "当前登录状态已过期，请重新登录",
          type: "warning",
        });
        setTimeout(() => {
          this.$router.push({
            path: "/2022sz/login",
          });
        }, 2000);
      }
    },
    toSubject(id) {
      this.$router.push({ path: "/2022sz/subject", query: { id: id } });
    },
    getPlaceTime() {
      this.$http
        .jsonp(getMeetingTime + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.status === "200") {
            const start = this.formatTime(res.data.startTime, "start");
            const end = this.formatTime(res.data.endTime, "end");
            this.place = `${res.data.address}`;
            this.time = `${start}~${end}`;
          }
        });
    },
    formatTime(timestamp, tag) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (tag === "start") {
        return `${year}.${month}.${day}`;
      } else {
        return `${month}.${day}`;
      }
    },
    getChairman() {
      this.$http
        .jsonp(getSueLecturer+sidlecturer1(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.errno == 0) {
            this.list = res.data[240];
          }
        });
    },
    getSelectedCase() {
      this.$http
        .jsonp(getSueLecturer+sidcase(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno === "0") {
            this.idList = res.data[242].map((item) => item.content);
            this.getCase();
          }
        });
    },
    getCase() {
      this.$http
        .jsonp(getAllCourse + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno === "0") {
            res.data.map((item) => {
              if (this.idList.indexOf(item.courseid) >= 0) {
                this.selectedCaseList.push(item);
              }
            });
          }
        });
    },
    getSponsor() {
      // 赞助
      this.$http
        .jsonp(getSue+sidsponsor(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.errno == 0) {
            this.sponsorList = res.data[0];
          }
        });
    },
    splitSponsor(list) {
      const newList = {};
      list.map((item) => {
        const keyList = Object.keys(newList);
        if (keyList.indexOf(item.typeName) !== -1) {
          newList[item.typeName].push(item);
        } else {
          newList[item.typeName] = [];
        }
      });
      return newList;
    },
    getTheme: function () {
      this.$http
        .jsonp(getThemeInfo + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res)
          if (res.errno == 0) {
            this.themeList = this.splitTheme(res.data, 4);
            // console.log(this.themeList);
          }
        });
    },
    splitTheme(themeList, num) {
      const list = [];
      for (let i = 0; i < themeList.length; ) {
        list.push(themeList.slice(i, (i += num)));
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.giac-container {
  button {
    cursor: pointer;
  }
  .giac {
    width: 100%;
    .space {
      margin: 0 auto;
      width: 1200px;
      border-left: 1px solid #edf5fc;
      border-right: 1px solid #edf5fc;
      border-top: none;
      border-bottom: none;
    }
    .space.chairman-publisher {
      height: 70px;
    }
    .space.selected-case {
      height: 67px;
    }
    .space.sponsor {
      height: 71px;
    }
  }
  .banner {
    position: relative;
    margin-bottom: -5px;
    .place-time {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 26px;
      font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
      font-weight: 500;
      text-align: center;
      color: #003177;
      line-height: 40px;
    }
  }
  .banner-ul {
    background: #f1f4f7;
    ul {
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      li {
        padding: 53px 20px;
        h2 {
          font-size: 60px;
          font-weight: bold;
          font-family: Arial, Arial-Bold;
          text-align: center;
          color: #000000;
        }
        p {
          font-size: 19px;
          font-family: Source Han Sans CN;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
  .meeting-intro {
    border: 1px solid #edf5fc;
    width: 1202px;
    margin: 0 auto;
    padding: 51px 0;
    border-top: none;
    border-bottom: none;
    p {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      color: #484848;
      text-align: left;
    }
    h4 {
      margin-top: 27px;
      margin-bottom: 50px;
      color: #00b3d4;
      font-size: 36px;
      text-align: left;
      letter-spacing: 0px;
      font-family: PingFang SC;
      font-weight: 500;
    }
    .text {
      padding-left: 2px;
      padding-right: 2px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      color: #4c4c4c;
      line-height: 30px;
    }
  }
  .buy-ticket {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1200px;
      height: 26px;
      border-left: 1px solid #edf5fc;
      border-right: 1px solid #edf5fc;
      z-index: 10;
    }
    .buy-bg {
      height: 200px;
      position: relative;
      width: 100%;
    }
    .buy-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: absolute;
      bottom: 36px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      p {
        font-size: 17px;
        font-family: Arial;
        color: #dadada;
        letter-spacing: 0px;
      }
      img {
        margin-top: 19px;
        margin-bottom: 16px;
      }
      button {
        width: 180px;
        height: 48px;
        background: #ffffff;
        border-radius: 6px;
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        line-height: 30px;
        letter-spacing: 0px;
        outline: none;
        border: none;
        a {
          color: #003177;
          text-decoration: none;
        }
      }
      button:hover {
        opacity: 0.8;
      }
    }
  }
  .chairman-publisher {
    width: 1202px;
    margin: 0 auto;
    border: 1px solid #edf5fc;
    border-top: none;
    border-bottom: none;
    .chairman,
    .publisher {
      width: 100%;
      padding-top: 91px;
      .bg-line {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 55px;
        position: relative;
        width: 590px;
        height: 5px;
        padding: 12px 0;
        line-height: 23px;
        p {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          text-align: center;
          font-family: PingFang SC;
          color: #ffffff;
          z-index: 10;
        }
        img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          margin-right: 25px;
          display: flex;
          flex-direction: column;
          padding-bottom: 22px;
          .picture {
            height: 241px;
            img {
              width: 220px;
              height: 241px;
              border-radius: 7px;
              border: 1px solid #f7f7f7;
            }
          }
          .triangle {
            text-align: left;
            padding-top: 8px;
            padding-bottom: 10px;
            line-height: 12px;
          }
          .info {
            padding-bottom: 133px;
            h4 {
              margin-bottom: 9px;
              text-align: left;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #010101;
              line-height: 29px;
            }
            p {
              width: 196px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              text-align: left;
              color: #666666;
              line-height: 24px;
            }
          }
        }
        li:nth-child(2),
        li:nth-child(4),
        li:nth-child(7),
        li:nth-child(9),
        li:nth-child(12),
        li:nth-child(14),
        li:nth-child(17),
        li:nth-child(19) {
          flex-direction: column-reverse;
          border-bottom: 1px dashed #003177;
          .triangle {
            img {
              transform: rotate(180deg);
            }
          }
          .info {
            padding-bottom: 0;
            padding-top: 74px;
          }
        }
        li:nth-child(5),
        li:nth-child(10),
        li:nth-child(15),
        li:nth-child(20) {
          margin-right: 0;
        }
        li:nth-last-child(1),
        li:nth-last-child(2),
        li:nth-last-child(3),
        li:nth-last-child(4) {
          border-bottom: 1px dashed transparent;
        }
      }
    }
    .chairman {
      padding-top: 38px;
      .bg-line {
        margin-bottom: 63px;
      }
    }
    .loading {
      padding: 99px 0 69px;
      color: #00b3d4;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
    }
  }
  .theme-setting {
    padding-top: 75px;
    padding-bottom: 88px;
    background: #fafbfc;

    .pc-table {
      background: #fff;
      margin: 0 auto;
      text-align: left;
      box-sizing: border-box;
      border-collapse: collapse;
      font-family: PingFang SC, PingFang SC-Regular;
      text-align: left;
      color: #373737;
      line-height: 19px;
      thead {
        font-size: 18px;
        th:first-child {
          width: 72px;
          padding-left: 16px;
        }
        th {
          font-weight: 500;
          width: 202px;
          height: 72px;
          line-height: 22px;
          // 修改标题字体
          font-size: 15px;
          padding-left: 9px;
          padding-right: 9px;
          text-align: center;
        }
        th:last-child {
          width: 208px;
        }
      }
      tbody {
        font-size: 18px;
        height: 273px;
        th:first-child {
          font-size: 18px;
          padding-left: 0;
          text-align: center;
          font-weight: 500;
          font-family: PingFang SC, PingFang SC-Regular;
          color: #373737;
          line-height: 25px;
        }
        th:last-child,
        th:nth-last-child(2) {
          width: 230px;
        }
        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div {
            line-height: 26px;
            cursor: pointer;
          }
        }
        th {
          padding-top: 31px;
          padding-bottom: 26px;
          font-weight: normal;
          font-size: 16px;
          height: 214px;
          color: #043375;
        }
      }
      th {
        padding-left: 18px;
        border: 1px solid #e5e5e5;
      }
    }
    h4 {
      font-size: 36px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #010101;
    }
    p {
      margin-top: 21px;
      margin-bottom: 68px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      text-align: center;
      color: #373737;
      line-height: 29px;
    }
  }
  .case-wrapper {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #edf5fc;
    border-top: none;
    border-bottom: none;
    .all-case {
      margin-bottom: 69px;
      padding: 20px 60px;
      background: #003177;
      border-radius: 8px;
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      text-align: center;
      border: none;
      outline: none;
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
    .all-case:hover {
      opacity: 0.8;
    }
  }
  .comment {
    position: relative;
    img {
      max-width: 100%;
      min-height: 400px;
    }
    .comment-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      h4 {
        margin-top: 60px;
        margin-bottom: 48px;
        font-size: 36px;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Regular;
        text-align: center;
        color: #ffffff;
      }
      p {
        text-indent: 2em;
        margin: 0 auto;
        width: 1005px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        text-align: left;
        color: #ffffff;
        line-height: 31px;
        span {
          display: block;
          text-align: right;
        }
      }
    }
  }
  .public-title {
    width: 100%;
    border: 1px solid #edf5fc;
    .inner {
      position: relative;
      padding-left: 6px;
      width: 1202px;
      margin: 0 auto;
      border-left: 1px solid #edf5fc;
      border-right: 1px solid #edf5fc;
      border-top: none;
      border-bottom: none;
      em {
        position: absolute;
        display: inline-block;
        background: url(../assets/img/spot-icon.png) no-repeat -10px -225px;
        width: 6px;
        height: 6px;
        z-index: 2;
      }
      em.lt {
        left: -3px;
        top: -3px;
      }
      em.rt {
        right: -3px;
        top: -3px;
      }
      em.lb {
        left: -3px;
        bottom: -3px;
      }
      em.rb {
        right: -3px;
        bottom: -3px;
      }
      p {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Bold;
        color: #484848;
        text-align: left;
      }
      h4 {
        margin-top: 27px;
        color: #003177;
        font-size: 36px;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Medium;
        text-align: left;
        letter-spacing: 0px;
      }
    }
  }

  .invitation-wrapper {
    background: #003177;
    .invitation {
      display: flex;
      justify-content: center;
      padding-top: 33px;
      padding-bottom: 33px;
      img {
        max-width: 100%;
      }
      p {
        padding-left: 86px;
        padding-right: 87px;
        font-size: 24px;
        // font-weight: 500;
        font-family: PingFang SC, PingFang SC-Medium;
        text-align: left;
        color: #ffffff;
        line-height: 54px;
        height: 54px;
        letter-spacing: 2px;
      }
      div {
        display: flex;
        align-items: center;
        button {
          padding: 12px 45px;
          background: #ffffff;
          border-radius: 5px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: bold;
          text-align: center;
          line-height: 16px;
          letter-spacing: 1px;
          border: none;
          outline: none;
          a {
            color: #003177;
            text-decoration: none;
          }
        }
      }
      button:hover {
        opacity: 0.8;
      }
    }
  }
}
@media (max-width: 1023px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
  .giac-container {
    margin-top: 0;
    .banner {
      .place-time {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .banner-ul {
      ul {
        width: 100vw;
        li {
          padding: 20px 18px 14px 21px;
          h2 {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            color: #000000;
          }
          p {
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
    .public-title {
      width: 100vw;
      border: none;
      .inner {
        width: 86%;
        border: none;
        em {
          display: none;
        }
        p {
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #484848;
        }
        h4 {
          margin-top: 10px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
        }
      }
    }
    .meeting-intro {
      width: 100vw;
      padding: 30px 7vw 24px;
      border: none;
      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #4c4c4c;
        line-height: 24px;
      }
    }
    .buy-ticket {
      &::before {
        width: 100%;
      }
      .buy-bg {
        height: auto;
        max-width: 100%;
      }
      .buy-content {
        bottom: 23%;
        p {
          display: none;
        }
        img {
          display: none;
        }
        button {
          width: 130px;
          height: 42px;
          color: #043375;
          font-size: 15px;
        }
      }
    }

    .theme-setting {
      background: #f1f4f7;
      padding-left: 7vw;
      padding-right: 7vw;
      padding-top: 40px;
      padding-bottom: 40px;
      h4 {
        font-size: 18px;
      }
      p {
        margin-top: 11px;
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 20px;
      }
      .pc-theme {
        display: none;
      }
      .pc-table {
        display: none;
      }
      .mob-theme {
        display: block;
      }
      .mob-table {
        width: 100%;
        background: #fff;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        border-collapse: collapse;
        font-family: PingFang SC;
        text-align: left;
        color: #373737;
        line-height: 19px;
        thead {
          width: 100%;
          font-size: 14px;
          text-align: center;
          th:first-child {
            width: 33.33%;
          }
          th:last-child {
            padding-left: 12.5vw;
            padding-right: 20vw;
          }
          th {
            width: 66.66%;
            font-weight: 500;
            line-height: 35px;
          }
        }
        tbody {
          font-size: 18px;
          tr:last-child {
            border-bottom: 1px solid #e5e5e5;
          }
          tr:first-child {
            th:last-child {
              border-top: none;
            }
          }
          // th:first-child {
          //   font-size: 14px;
          //   padding-left: 0;
          //   text-align: center;
          //   font-weight: 500;
          //   font-family: PingFang SC;
          //   color: #373737;
          // }
          .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-top: 19px;
            padding-bottom: 22px;
            padding-left: 5vw;
            padding-right: 5vw;
            border-left: none;
            border-bottom: none;
            .left,
            .right {
              text-align: center;
              line-height: 21px;
              width: 50%;
            }
          }
          th {
            font-weight: normal;
            font-size: 14px;
            color: #043375;
            line-height: 24px;
          }
        }
        th {
          text-align: center;
          border: 1px solid #e5e5e5;
        }
      }
    }
    .case-wrapper {
      width: 100vw;
      border: none;
      .all-case {
        font-size: 15px;
        margin-bottom: 35px;
        padding: 11px 32px;
        border-radius: 4px;
      }
    }
    .comment {
      .comment-content {
        h4 {
          font-size: 18px;
          margin-top: 42px;
          margin-bottom: 24px;
        }
      }
      img {
        min-height: auto;
      }
    }
    .invitation-wrapper {
      background: #003177;
      .invitation {
        display: flex;
        justify-content: center;
        padding-top: 32px;
        padding-bottom: 30px;
        img {
          display: none;
        }
        p {
          margin-right: 7.5vw;
          padding-left: 0;
          padding-right: 0;
          width: 52vw;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 1px;
        }
        div {
          button {
            padding: 13px 17px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #043375;
            line-height: 16px;
            background: #ffffff;
            border-radius: 6px;
            border: none;
            outline: none;
          }
        }
      }
    }
    .giac {
      .space.chairman-publisher {
        height: 42px;
      }
      .space.meeting-intro {
        height: 30px;
        padding: 0;
      }
      .space.sponsor {
        height: 32px;
      }
      .space.selected-case {
        height: 36px;
      }
      .space {
        width: 100vw;
        border: none;
      }
    }
  }
}
@media (min-width: 1024px) {
  .pc {
    display: block;
  }
  .mob {
    display: none;
  }
  .mob-theme {
    display: none;
  }
  .mob-table {
    display: none;
  }
}
</style>