<template>
  <div class="com_sponsorlist" :class="_from">
    <div class="sponsor" v-for="(sponsor, key) in contentList" :key="key">
      <div class="sponsor-option">
        <p class="title">{{ key }}</p>
        <p class="desc_2022sz" v-if="global.year=='2022sz'&&key=='大会指定机票 酒店合作平台'">点击[艺龙旅行] 领取百元出行神券</p>
        <ul v-if="sponsor.length > 0" :class="global.year=='2022sz'&&key=='大会指定机票 酒店合作平台'?'y2022sz':''">
          <li v-for="(item, index) in sponsor" :key="'com_sponsor_'+index">
            <!--   v-if="item.content !== ''" -->
            <a :href="item.content === '' ? '#' : item.content" target="_blank">
              <img :src="item.file" alt="" />
              <!-- <img :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" /> -->
            </a>
            <!-- <img v-else :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" /> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getSue, getSueType, sidsponsor, sponsorcontent } from "@/utils/api.js";
export default {
  props: {
    // _dom: Object,
    _from: String,
    _ispc: [Boolean, Number, String],
  },
  data() {
    return {
      loc: '',
      sponsorList: [],
      cooperatorList: [],
      contentList: {},
    };
  },
  mounted() {
    this.getSponsor();
    // this.getCooperator();
  },
  computed: {
    scrollData() {
      return [this.loc, this.contentList];
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if(to && to.path.includes('/home') && to.query &&to.query.loc==='sponsor') {
          this.loc = 'sponsor';
        } else {
          this.loc = '';
        }
      },
      immediate: true,
      deep: true,
    },
    scrollData: {
      handler(_new, _old) {
        console.log('scrollData ', _new, _old)
        if(_new && _new[0] && _new[1] && typeof _new[1]==='object' && Object.keys(_new[1]).length>0) {
          if(_new[0] == 'sponsor') {
            this.winScroll();
          } else {
            window.scrollTo(0, 0);
          }
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    winScroll() {
      setTimeout(()=>{
        // console.log('loc ', $('.home_ .giac-container.sponsor').offset().top);
        let difference = this._ispc ? 120 : 70;
        let top = $('.home_ .giac-container.sponsor').offset().top - difference;
        window.scrollTo(0, top);
      }, 20)
    },
    getSponsor() {
      this.$http
        .jsonp(getSue+sidsponsor(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(this.global.year, res);
          if (res.errno == 0) {
            this.splitList(res.data[0]);
            // this.sponsorList = res.data[0];
            // this.cooperatorList = res.data[0];
          } else {
            this.splitList([]);
          }
        });
      // this.$http.jsonp(getSueType+sidsponsor(this.global.year))
      //   .then((data) => {
      //     return data.json();
      //   })
      //   .then((res) => {
      //     console.log('getSueType ', res);
      //   })
    },
    splitList(list) {
      this.contentList = {...sponsorcontent(this.global.year)};
        // this.global.year=="2022sz"
        // ? {
        //     '银牌赞助': [],
        //     '铜牌赞助': [],
        //     '大会指定机票 酒店合作平台': [],
        //     '合作推广伙伴': [],
        //   }
        // : {
        //     '金牌赞助': [],
        //     '银牌赞助': [],
        //     '铜牌赞助': [],
        //     '合作社区': [],
        //     '合作媒体': [],
        //     '图书赞助': [],
        //   };
      Object.keys(this.contentList).map((item) => {
        this.contentList[item] = [];
      })
      list.map((item) => {
        console.log(item.typeName)
        item.typeName&&this.contentList[item.typeName] ? this.contentList[item.typeName].push(item) : "";
      });
    },
    // splitSponsor(list) {
    //   const newList = {
    //     金牌赞助: [],
    //     银牌赞助: [],
    //     铜牌赞助: [],
    //     图书赞助: [],
    //   };
    //   list.map((item) => {
    //     if (item.detailType === "134") {
    //       newList["金牌赞助"].push(item);
    //     } else if (item.detailType === "135") {
    //       newList["银牌赞助"].push(item);
    //     } else if (item.detailType === "136") {
    //       newList["铜牌赞助"].push(item);
    //     } else if (item.detailType === "137") {
    //       newList["图书赞助"].push(item);
    //     }
    //   });
    //   return newList;
    // },
    // getCooperator() {
    //   this.$http
    //     .jsonp()
    //     .then((data) => {
    //       return data.json();
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if (res.errno == 0) {
    //         this.cooperatorList = res.data[0];
    //       }
    //     });
    // },
    // splitCooperator(list) {
    //   const newList = {
    //     合作社区: [],
    //     合作媒体: [],
    //     // 合作赞助: [],
    //     // 独家人力资源合作伙伴: [],
    //   };
    //   list.map((item) => {
    //     if (item.detailType === "132") {
    //       newList["合作媒体"].push(item);
    //     } else if (item.detailType === "202") {
    //       newList["合作社区"].push(item);
    //     } else if (item.detailType === "130") {
    //       newList["合作赞助"].push(item);
    //     } else if (item.detailType === "133") {
    //       newList["独家人力资源合作伙伴"].push(item);
    //     }
    //   });
    //   return newList;
    // },
  },
};
</script>

<style lang="scss" scoped>
.sponsor {
  // width: 1202px;
  margin: 0 auto;
  // padding-bottom: 42px;
  border: 1px solid #edf5fc;
  border-bottom: none;
  border-top: none;
  &:last-child {
    padding-bottom: 50px;
  }
  .sponsor-option {
    p {
      // padding-top: 70px;
      padding-top: 42px;
      padding-bottom: 42px;
      font-size: 24px;
      font-weight: 500;
      font-family: PingFang SC, PingFang SC-Regular;
      text-align: center;
      color: #003177;
    }
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        // img {
        //   height: 68px;
        //   width: 180px;
        // }
      }
    }
  }
}

// new home
.source_home {
  .sponsor {
    width: 100%;
    border: none;
    .sponsor-option {
      max-width: 1200px;
      margin: 0 auto;
      ul {
        li {
          width: 200px;
          height: 80px;
          margin-bottom: 40px;
          display: table;
          a {
            width: 100%;
            height: 100%;
            // display: inline-block;
            display: table-cell;
            vertical-align: middle;
            background: #FFFFFF;
            border-radius: 8px;
            box-shadow: 0px 0px 14px 0px rgba(43, 66, 131, 0.07);
            img {
              width: 100%;
              height: auto;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .com_sponsorlist:not(.source_home) {
    .sponsor {
      width: 100vw;
      border: none;
      padding-bottom: 0;
      .sponsor-option {
        p {
          font-size: 15px;
          padding-top: 44px;
          padding-bottom: 29px;
        }
        ul {
          margin-bottom: 0;
          li {
            width: 33.33%;
            padding-left: 10px;
            padding-right: 10px;
            a {
              cursor: pointer;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .sponsor:last-child {
      padding-bottom: 53px;
    }
  }
}

@media (max-width: 767px) {
  .source_home {
    .sponsor {
      .sponsor-option {
        p.title {
          padding: 0!important;
          margin-top: 35px!important;
          margin-bottom: 10px;
        }
        ul {
          justify-content: space-around;
          li {
            width: 23.4%;
            width: 150px;
            min-width: 150px;
            height: 55px;
            padding: 0;
            margin: 0 5px;
            margin-top: 15px;
            background: #FFFFFF;
            box-shadow: 0px 0px 14px 0px rgb(43 66 131 / 7%);
            border-radius: 8px;
            overflow: hidden;
            display: table;
            a {
              padding: 0 8px;
            }
          }
        }
      }
    }
  }
}
</style>